import React, { useContext } from 'react';
import Toggle from 'react-toggle';
import { FaMoon, FaSun } from 'react-icons/fa';
import { graphql, useStaticQuery } from 'gatsby';
import { Link, useTranslation, useI18next, I18nextContext } from 'gatsby-plugin-react-i18next';

import { ThemeContext } from './ThemeProvider';
import NavigationBar from './NavigationBar';
import Dropdown from './Dropdown';

import "../styles/components/Header.scss";
import * as headerStyles from '../styles/components/Header.module.scss';

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const { isDarkMode, toggleDarkMode } = useContext(ThemeContext);
  const { languages, changeLanguage } = useI18next();
  const context = useContext(I18nextContext);
  const { t } = useTranslation('header');

  const items = [
    {
      displayName: t("Home"),
      relativePath: "/"
    },
    {
      displayName: t("Blog"),
      relativePath: "/blog"
    },
    {
      displayName: t("About"),
      relativePath: "/about"
    },
    {
      displayName: t("Contact"),
      relativePath: "/contact"
    },
  ];

  const dropdownItems = languages.map((lang) => {
    return { 'id': lang, 'name': lang };
  });

  const { title } = data.site.siteMetadata;

  return (
      <header className={headerStyles.header}>
        <h1>
          <Link className={headerStyles.title} to="/">
            {t(title)}
          </Link>
        </h1>
        <div className={headerStyles.navbarContainer}>
          <NavigationBar navItems={items} />
          
          <div className={headerStyles.rightSide}>
            <Toggle 
              checked={isDarkMode}
              className='custom-toggle'
              icons={{
                checked: <FaMoon color='white' size={10} />,
                unchecked: <FaSun color='white' size={10} />
              }}
              onChange={(_) => toggleDarkMode()}
            /> 
            <Dropdown 
              title={context.language}
              items={dropdownItems}
              action={changeLanguage}
            />
          </div>
        </div>
      </header>
  );
}

export default Header;
