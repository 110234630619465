import React, { useContext } from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import classNames from 'classnames';

import { ThemeContext } from './ThemeProvider';
import * as navigationBarStyles from '../styles/components/NavigationBar.module.scss';

const NavigationBar = ({ navItems }) => {
  const { isDarkMode } = useContext(ThemeContext);

  return (
    <nav>
      <ul className={navigationBarStyles.navList}>
        {navItems.map((item) => (
          <li key={item.displayName}>
            {createNavItem(item, isDarkMode)}	
          </li>
        ))} 
      </ul>
    </nav>
  );
}



const createNavItem = ({ displayName, relativePath }, isDarkMode) => {

  // Creates the className because CSS modules are annoying
  const navItemClass = classNames({
    [navigationBarStyles.navItem]: true,
    [navigationBarStyles.darkTheme]: isDarkMode
  });

  return (
    <Link 
      className={navItemClass}
      activeClassName={navigationBarStyles.activeNavItem}
      to={relativePath}>
        {displayName}
    </Link>
  );
};

export default NavigationBar;
